import React, { useEffect } from "react";
import { Switch } from "react-router-dom";

import Route from "./Route";
import GATracker from "./GATracker";

import Login from "~/pages/Login";
import Main from "~/pages/Main";
import Transporter from "~/pages/Transporter";
import Geolocation from "~/pages/Geolocation";
import ReadBarCode from "~/pages/ReadBarCode";
import { useDispatch } from "react-redux";
import useOnlineStatus from "~/hooks/useOnlineStatus";
import { setConnectionStatus } from "~/store/modules/connection/actions";
import Delivery from "~/pages/Delivery";

export default function Routes() {
  const dispatch = useDispatch();
  const isOnline = useOnlineStatus();

  useEffect(() => {
    dispatch(setConnectionStatus(isOnline));
  }, [isOnline, dispatch]);
  return (
    <Switch>
      <Route path="/" exact component={GATracker(Login)} />
      <Route path="/main" component={GATracker(Main)} isPrivate />
      <Route path="/delivery" component={GATracker(Delivery)} isPrivate />
      <Route path="/transporter" component={GATracker(Transporter)} isPrivate />
      <Route path="/geolocation" component={GATracker(Geolocation)} isPrivate />
      <Route path="/barcode" component={GATracker(ReadBarCode)} isPrivate />
    </Switch>
  );
}
