import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

function Offline({ children }) {
  const connected = useSelector(states => states.connection.Connected);

  return !connected ? <>{children}</> : null;
}

Offline.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Offline;
