/* eslint-disable no-param-reassign */
import produce from "immer";
import { Actions } from "./actions";

const INITIAL_STATE = {
  image: false,
};

export default function imageviewer(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Actions.SHOW_IMAGE: {
        draft.image = action.payload.image;
        break;
      }
      default:
    }
  });
}
