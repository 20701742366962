/* eslint-disable no-param-reassign */
import produce from "immer";
import { Actions as AuthActions } from "~/store/modules/auth/actions";

const INITIAL_STATE = {
  user: {
    userId: null,
    isTransporter: false,
    vehicle: 0,
  },
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case AuthActions.SIGN_IN_SUCCESS: {
        draft.user = action.payload.user;
        break;
      }
      case AuthActions.SIGN_OUT: {
        draft.user = {
          userId: null,
          isTransporter: false,
          vehicle: 0,
        };
        break;
      }
      default:
    }
  });
}
