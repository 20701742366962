import Ws from "@adonisjs/websocket-client";

const url = `${process.env.REACT_APP_API_URL}`.replace(
  /http(s)?:\/\/([\w\d:.]+\/).*/i,
  "ws$1://$2"
);
const wsUrl = process.env.REACT_APP_WS_API_URL ?? url;
const ws = Ws(wsUrl, { reconnectionAttempts: 86400 });

export default ws;
