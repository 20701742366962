import styled from "styled-components";

export const Container = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background-color: #fff;
  margin: 10px 5px;
  padding: 10px;
`;

export const CardTitleContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const CardTitle = styled.h1`
  font-size: 1.3em;
`;
export const CardTitleClientCode = styled.h1`
  font-size: 1.3em;
  font-weight: bold;
`;

export const CardContent = styled.p`
  color: #555;
  margin: 0;
`;
export const ContentLowOpacity = styled.p`
  color: #555;
  opacity: 0.5;
`;

export const CardActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;
