export const filterDelivery = (delivery, search) => {
  const filteredDelivery = delivery
    .map(delivery => {
      if (delivery.nfes.length) {
        const hasNumber = delivery.nfes.some(nfe => {
          return nfe.numero.includes(search);
        });
        if (hasNumber) {
          return delivery;
        }
      }
    })
    .filter(delivery => delivery !== undefined);
  return filteredDelivery;
};
