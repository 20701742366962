import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

function Online({ children }) {
  const connected = useSelector(states => states.connection.Connected);

  return connected ? <>{children}</> : null;
}

Online.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Online;
