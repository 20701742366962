import { all } from "redux-saga/effects";

import auth from "./auth/sagas";
import user from "./user/sagas";
import destinations from "./destinations/sagas";
import geolocation from "./geolocation/sagas";
import queue from "./queue/sagas";
import connection from "./connection/sagas";

export default function* rootSaga() {
  return yield all([auth, user, destinations, geolocation, queue, connection]);
}
