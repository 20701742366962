import React from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";

import { DeliverWithoutGpsBtn, UnavailableModal } from "./style";

import { ClearButton } from "~/components/ClearButton";

export default function GpsUnavailableModal({
  show,
  handleConfirmDeliverySent,
  setShow,
}) {
  function handleClose() {
    setShow(false);
  }

  function handleConfirm() {
    handleConfirmDeliverySent();
    setShow(false);
  }

  return (
    <UnavailableModal show={show} centered>
      <Modal.Header>
        <Modal.Title>Não foi possível verificar sua localização!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Siga as recomendações abaixo:</p>
        <p>&#8210; Verifique se o seu GPS está ativado;</p>
        <p>&#8210; Vá para outro lugar para ver se melhora o sinal do GPS.</p>
      </Modal.Body>
      <Modal.Footer>
        <DeliverWithoutGpsBtn type="button" onClick={handleConfirm}>
          ENTREGAR SEM GPS
        </DeliverWithoutGpsBtn>
        <ClearButton type="button" onClick={handleClose}>
          CANCELAR
        </ClearButton>
      </Modal.Footer>
    </UnavailableModal>
  );
}

GpsUnavailableModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setConfirm: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
};
