import React, { useEffect, useState } from "react";
import { BrowserBarcodeReader } from "@zxing/library";
import { useSelector, useDispatch } from "react-redux";

import {
  BarCodeReaderWrapper,
  Video,
  BackIcon,
  LastBarcode,
  ErrorMessage,
} from "./style";
import history from "~/services/history";
import api from "~/services/api";
import { redirectToMain } from "~/store/modules/auth/actions";

const BarCodeReader = () => {
  const videoStreamRef = React.createRef();
  const dispatch = useDispatch();
  const { userId, vehicle } = useSelector(states => states.user.user);
  const [lastBarcode, setLastBarcode] = useState("");
  const [barcodes, setBarcodes] = useState([]);
  const [error, setError] = useState();
  const [codeReader] = useState(new BrowserBarcodeReader());

  function handleBack() {
    dispatch(redirectToMain());
    history.push("/main");
  }

  useEffect(() => {
    codeReader.reset();

    codeReader
      .decodeOnceFromVideoDevice(undefined, videoStreamRef.current)
      .then(async result => {
        try {
          // eslint-disable-next-line eqeqeq, radix, no-restricted-globals
          if (result.text != null || isNaN(parseInt(result.text)) == false) {
            if (!barcodes.includes(result.text)) {
              setBarcodes([result.text, ...barcodes]);

              setLastBarcode(result.text);

              await api.put("/vincular-minutas", {
                cdTransportador: userId,
                cdEntregador: userId,
                nrCodigoBarrasMinuta: result.text,
                dsPlacaVeiculoEntrega: vehicle,
              });
            } else {
              setLastBarcode("Minuta já carregada");
            }
          }
        } catch (err) {
          setError(`Ocorreu um erro ao vincular a minuta: ${result.text}.`);
        }
      })
      .catch(() => {
        setError("Ocorreu um erro ao ler o código de barras da minuta.");
      });
    return function cleanup() {
      codeReader.reset();
    };
  }, [videoStreamRef, codeReader, barcodes, userId, lastBarcode, vehicle]);

  return (
    <BarCodeReaderWrapper>
      <BackIcon onClick={handleBack} />
      {error ? (
        <ErrorMessage>{error}</ErrorMessage>
      ) : (
        <LastBarcode>Último código lido: {lastBarcode}</LastBarcode>
      )}
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <Video ref={videoStreamRef} />
    </BarCodeReaderWrapper>
  );
};

export default BarCodeReader;
