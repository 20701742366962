import axios from "axios";
import { setupCache } from "axios-cache-adapter";

const cache = setupCache({
  maxAge: 0,
});

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  adapter: cache.adapter,
});

export default api;
