import React from "react";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";

import { ConfirmModal } from "./style";

import { PrimaryButton } from "~/components/PrimaryButton";

export default function RequireImageModal({ show, setShow }) {
  function handleClose() {
    setShow(false);
  }

  return (
    <ConfirmModal show={show} onHide={() => {}} centered>
      <Modal.Header>
        <Modal.Title>Esta entrega exige foto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Esta entrega exige foto. Clique no botão Abrir câmera para tirar a foto
        da entrega
      </Modal.Body>
      <Modal.Footer>
        <PrimaryButton type="button" onClick={handleClose}>
          OK
        </PrimaryButton>
      </Modal.Footer>
    </ConfirmModal>
  );
}

RequireImageModal.propTypes = {
  show: PropTypes.bool.isRequired,
  setShow: PropTypes.func.isRequired,
};
