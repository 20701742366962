export const Actions = {
  DESTINATIONS_TO_DELIVER: "@destinations/DESTINATIONS_TO_DELIVER",
  DESTINATIONS_DELIVERED: "@destinations/DESTINATIONS_DELIVERED",
  DESTINATIONS_INFO: "@destinations/DESTINATIONS_INFO",
  SET_DELIVERED: "@destinations/SET_DELIVERED",
  SET_NEW_OBSERVATION: "@destinations/SET_NEW_OBSERVATION",
  UPDATE_DESTINATIONS_INFO: "@destinations/UPDATE_DESTINATIONS_INFO",
  DESTINATIONS_ERROR: "@destinations/DESTINATIONS_ERROR",
  SEND_FILE: "@destinations/SEND_FILE",
  SET_LOADING_SEND_FILE: "@destinations/SET_LOADING_SEND_FILE",
  SET_SEND_FILE_RETURN: "@destinations/SET_SEND_FILE_RETURN",
};

export function setDestinationsToDeliver(toDeliver) {
  return {
    type: Actions.DESTINATIONS_TO_DELIVER,
    payload: { toDeliver },
  };
}

export function setDestinationsDelivered(delivered) {
  return {
    type: Actions.DESTINATIONS_DELIVERED,
    payload: { delivered },
  };
}

export function setDestinationsInfo(info) {
  return {
    type: Actions.DESTINATIONS_INFO,
    payload: { info },
  };
}
export function setDelivered(delivery) {
  return {
    type: Actions.SET_DELIVERED,
    payload: { delivery },
  };
}

export function setNewObservation(delivery) {
  return {
    type: Actions.SET_NEW_OBSERVATION,
    payload: { delivery },
  };
}

export function updateDestinationsInfo() {
  return {
    type: Actions.UPDATE_DESTINATIONS_INFO,
  };
}

export function setDestinationsError() {
  return {
    type: Actions.DESTINATIONS_ERROR,
  };
}

export function sendFile(payload) {
  return {
    type: Actions.SEND_FILE,
    payload,
  };
}

export function setLoadingSendFile(loading) {
  return {
    type: Actions.SET_LOADING_SEND_FILE,
    payload: loading,
  };
}

export function setSendFileReturn(payload) {
  return {
    type: Actions.SET_SEND_FILE_RETURN,
    payload,
  };
}
