import React, { useState } from "react";
import { FiPackage } from "react-icons/fi";
import TruckIcon from "~/assets/images/icons/Truck.svg";
import history from "~/services/history";
import { AiOutlineWarning } from "react-icons/ai";
import {
  CollectCardContainer,
  DateLimitToCollectContainer,
  DeliveryCardHeaderContainer,
  DeliveryCardTitle,
  DeliveryCardTSubTitle,
  DeliveryInfosContainer,
  InfoGroup,
  InfoTitle,
  InfoValue,
  InQueueToSyncWarningContainer,
  PackageIconContainer,
  WarningIconContainer,
  WarningText,
} from "./styles";
import CustomButton, { CustomDarkButton } from "~/components/CustomButton";
import Observation from "~/pages/Observations";
import { formatDate } from "~/utils/treat_date";
import { ImageArea } from "./ImageArea";
import { useSelector } from "react-redux";

export default function CollectCard({ item }) {
  const itemType = item.idTipoEntrega;
  const cardColor = `${itemType === "E" ? "#2194CD" : "#CA851E"}`;
  const [modalObservationShow, setModalObservationShow] = useState(false);

  const { queue } = useSelector(state => state.queue);
  const onQueueItem = queue.find(
    delivery => delivery.sequencial === item.sequencial
  );

  const onQueue = onQueueItem !== undefined;

  const goToDeliveryPage = () => {
    history.push({
      pathname: "/delivery",
      state: { item: item },
    });
  };
  return (
    <>
      <Observation
        item={item}
        show={modalObservationShow}
        onHide={() => setModalObservationShow(false)}
      />
      <CollectCardContainer>
        <DeliveryCardHeaderContainer>
          <PackageIconContainer itemType={itemType}>
            {itemType === "E" ? (
              <FiPackage color={cardColor} size={25} />
            ) : (
              <img
                style={{
                  width: "25px",
                }}
                src={TruckIcon}
              />
            )}
          </PackageIconContainer>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <DeliveryCardTitle itemType={itemType}>
              {item.cdCliente}
              {item.nrDvCliente} - {item.nomeComercialFantasia.nomeFantasia}
            </DeliveryCardTitle>
            <DeliveryCardTSubTitle>
              Emitido em {formatDate(item.dtGeracao)}
            </DeliveryCardTSubTitle>
          </div>
        </DeliveryCardHeaderContainer>
        <hr style={{ width: "100%" }} />
        {!(itemType === "E") ? (
          <DateLimitToCollect date={formatDate(item.dtPrevisaoEntrega)} />
        ) : null}
        <DeliveryInfosContainer>
          <InfoGroup>
            <InfoTitle>Razão social</InfoTitle>
            <InfoValue>{item.nomeComercialFantasia.razaoSocial}</InfoValue>
          </InfoGroup>
          {itemType === "E" ? (
            <InfoGroup>
              <InfoTitle>Volume</InfoTitle>
              <InfoValue>
                {item.volumes < 10
                  ? item.volumes.toString().padStart(2, "0")
                  : item.volumes}
              </InfoValue>
            </InfoGroup>
          ) : null}
        </DeliveryInfosContainer>
        <hr style={{ width: "100%" }} />
        <DeliveryInfosContainer>
          <InfoGroup>
            <InfoTitle>Rua</InfoTitle>
            <InfoValue>{item.enderecoEntrega.rua}</InfoValue>
          </InfoGroup>
          <InfoGroup>
            <InfoTitle>Bairro</InfoTitle>
            <InfoValue>{item.enderecoEntrega.bairro}</InfoValue>
          </InfoGroup>
          <InfoGroup>
            <InfoTitle>Cidade</InfoTitle>
            <InfoValue>{item.enderecoEntrega.cidade}</InfoValue>
          </InfoGroup>
          <InfoGroup>
            <InfoTitle>CEP</InfoTitle>
            <InfoValue>{item.enderecoEntrega.cep}</InfoValue>
          </InfoGroup>
        </DeliveryInfosContainer>
        <hr style={{ width: "100%" }} />
        <div style={{ paddingLeft: "8px" }}>
          <InfoGroup>
            <InfoTitle>Número da nota fiscal</InfoTitle>
            <InfoValue>{item.nfes.map(nfe => nfe.numero).join(", ")}</InfoValue>
          </InfoGroup>
        </div>
        <ImageArea item={item} />
        <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
          {item.entregue === "S" && onQueue && (
            <InQueueToSyncWarningContainer>
              <WarningIconContainer>
                <AiOutlineWarning size={24} color="#D85656" />
              </WarningIconContainer>
              <WarningText>
                A entrega foi realizada, porém encontra-se em fila devido à sua
                indisponibilidade no momento.
              </WarningText>
            </InQueueToSyncWarningContainer>
          )}
        </div>
        <div
          style={{
            display: "flex",
            gap: "8px",
            marginTop: "16px",
          }}
        >
          <CustomDarkButton
            height={"40px"}
            text={"Observações"}
            isSelected={true}
            onClick={() => setModalObservationShow(true)}
          />
          {item.entregue === "N" ? (
            <CustomButton
              bgColor={cardColor}
              height={"40px"}
              text={`${itemType === "E" ? "Entregar" : "Recolher"}`}
              isSelected={true}
              onClick={() => {
                goToDeliveryPage();
              }}
            />
          ) : null}
        </div>
      </CollectCardContainer>
    </>
  );
}

export const DateLimitToCollect = ({ date }) => {
  const parseDate = dateString => {
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day);
  };

  const getBgColor = inputDate => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const dateToCompare = parseDate(inputDate);
    dateToCompare.setHours(0, 0, 0, 0);

    const timeDiff = dateToCompare - today;

    const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    if (daysDiff === 1) {
      return "#D85656"; // Tomorrow
    } else if (daysDiff === 3) {
      return "#D89C56"; // 3 days from today
    } else if (daysDiff > 3) {
      return "#4CB94A"; // More than 3 days
    } else {
      return "#D85656"; // Default color (optional)
    }
  };
  return (
    <DateLimitToCollectContainer bgColor={getBgColor(date)}>
      Data limite: {date ?? "-"}
    </DateLimitToCollectContainer>
  );
};
