import React, { Component } from "react";
import PropTypes from "prop-types";
import { watchLocation } from "~/services/watchLocation";

export class WatchLocationManager extends Component {
  componentDidMount() {
    const { success, error } = this.props;
    watchLocation(success, error);
  }

  render() {
    return <span hidden />;
  }
}

WatchLocationManager.propTypes = {
  success: PropTypes.func.isRequired,
  error: PropTypes.func.isRequired,
};

export default WatchLocationManager;
