import { END, eventChannel } from "redux-saga";
import { all, put, spawn, take } from "redux-saga/effects";

import connection from "../../../services/socket";
import { setConnectionStatus } from "./actions";

function watchConnection() {
  return eventChannel(emitter => {
    connection.connect();
    connection.on("open", () => {
      console.log("open");
      emitter(true);
    });
    connection.on("close", () => {
      console.log("close");
      emitter(false);
    });
    connection.on("error", () => {});
    return () => {
      connection.off("open");
      connection.off("close");
      connection.close();
      emitter(END);
    };
  });
}

function* HandleConnectionStatusChange() {
  const channel = watchConnection();
  while (true) {
    const status = yield take(channel);

    yield put(setConnectionStatus(status));
  }
}

export default all([spawn(HandleConnectionStatusChange)]);
