import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { FaCamera } from "react-icons/fa";
import { BsCheck, BsX } from "react-icons/bs";
import PropTypes from "prop-types";

import {
  Canvas,
  CenterDiv,
  ButtonsDiv,
  Flash,
  Video,
  VideoWrapper,
  RoundButton,
} from "./styles";
import { useUserMedia } from "~/hooks/useUserMedia";

const Camera = ({ onSave, onClose, onError }) => {
  let videoStreamRef = React.createRef();
  let snapshotRef = React.createRef();
  const container = React.createRef();

  function closeWithError(message) {
    onError(message);
  }

  const stream = useUserMedia({
    requestedMedia: {
      audio: false,
      video: {
        facingMode: "environment",
        aspectRatio: 1.7777777778,
      },
    },
    onError: closeWithError,
  });

  const [showCanvas, setShowCanvas] = useState(false);
  const [isFlashing, setIsFlashing] = useState(false);

  useEffect(() => {
    if (videoStreamRef.current && stream && !videoStreamRef.current.srcObject) {
      videoStreamRef.current.srcObject = stream;
    }
  }, [videoStreamRef, stream]);

  function close() {
    if (stream) {
      stream.getVideoTracks().forEach(track => {
        track.stop();
      });
    }
    if (onClose) {
      onClose();
    }
  }

  function handleCanPlay() {
    videoStreamRef.current.play();
  }

  function handleTakePictureClick() {
    const context = snapshotRef.current.getContext("2d");
    // Pegar o tamanho do vídeo na câmera
    const { videoWidth, videoHeight } = videoStreamRef.current;
    // Pegar o tamanho do vídeo exibido na tela
    const containerSize = container.current.getBoundingClientRect();

    const offsetX = Math.round((videoWidth - containerSize.width) / 2);
    const offsetY = Math.round((videoHeight - containerSize.height) / 2);

    const imgHeight =
      containerSize.height < videoHeight ? containerSize.height : videoHeight;

    snapshotRef.current.width = containerSize.width;
    snapshotRef.current.height = imgHeight;

    context.drawImage(
      videoStreamRef.current,
      offsetX,
      offsetY > 0 ? offsetY : 0,
      containerSize.width,
      imgHeight,
      0,
      0,
      containerSize.width,
      imgHeight
    );
    setShowCanvas(true);
    setIsFlashing(true);
  }

  function handleSavePictureClick() {
    try {
      snapshotRef.current.toBlob(blob => {
        const filename = `cade-${Math.floor(Date.now() / 1000)}.png`;
        saveAs(blob, filename);
        if (onSave) {
          onSave(blob, filename);
        }
        stream.getVideoTracks().forEach(track => {
          track.stop();
        });

        if (onClose) {
          onClose();
        }

        snapshotRef = null;
        videoStreamRef = null;
        close();
      });
    } catch (err) {
      console.log(err);
    }
  }

  const height = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );

  if (!stream) {
    return null;
  }

  return (
    <VideoWrapper>
      <CenterDiv maxHeight={height} ref={container}>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <Video
          autoPlay
          playsInline
          muted
          maxHeight={height}
          onCanPlay={handleCanPlay}
          ref={videoStreamRef}
          style={{ display: !showCanvas ? "block" : "none" }}
        />

        <Canvas
          maxHeight={height}
          ref={snapshotRef}
          style={{ display: showCanvas ? "block" : "none" }}
        />

        <Flash flash={isFlashing} onAnimationEnd={() => setIsFlashing(false)} />

        <ButtonsDiv>
          {showCanvas ? (
            <>
              <RoundButton
                color=""
                onClick={() => {
                  setShowCanvas(false);
                }}
              >
                <BsX size="32" />
              </RoundButton>
              <RoundButton primary onClick={handleSavePictureClick}>
                <BsCheck size="32" />
              </RoundButton>
            </>
          ) : (
            <>
              <RoundButton onClick={close}>
                <BsX size="32" />
              </RoundButton>
              <RoundButton primary squareIcon onClick={handleTakePictureClick}>
                <FaCamera size="20" />
              </RoundButton>
            </>
          )}
        </ButtonsDiv>
      </CenterDiv>
    </VideoWrapper>
  );
};

Camera.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default Camera;
