import React from "react";
import Modal from "react-bootstrap/Modal";
import { ConfirmModal } from "./style";

import { ClearButton } from "~/components/ClearButton";
import { PrimaryButton } from "~/components/PrimaryButton";

export default function ConfirmationModal({
  show,
  setShow,
  handleConfirmDeliverySent,
}) {
  function handleClose() {
    setShow(false);
  }

  function handleConfirm() {
    handleConfirmDeliverySent();
    setShow(false);
  }

  return (
    <ConfirmModal show={show} onHide={() => {}} centered>
      <Modal.Header>
        <Modal.Title>Confirmação de entrega</Modal.Title>
      </Modal.Header>
      <Modal.Body>Deseja confirmar a entrega?</Modal.Body>
      <Modal.Footer>
        <ClearButton type="button" onClick={handleClose}>
          NÂO
        </ClearButton>
        <PrimaryButton type="button" onClick={handleConfirm}>
          SIM
        </PrimaryButton>
      </Modal.Footer>
    </ConfirmModal>
  );
}
