import styled from "styled-components";

export const CollectCardContainer = styled.div`
  padding: 8px;
  border-radius: 8px;
  background: #fefefe;
  margin-bottom: 16px;
  width: 100%;
  overflow: hidden; /* Ensures no overflow outside the card */
  text-overflow: ellipsis; /* Clips overflowing text, showing ellipsis if necessary */
  white-space: nowrap; /* Prevents text wrapping */
`;

export const DeliveryCardHeaderContainer = styled.div`
  display: flex;
  padding: 8px;
`;

export const PackageIconContainer = styled.div`
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  height: 48px;
  width: 48px;
  background: ${({ itemType }) =>
    itemType === "E" ? "#2194CD1A" : "#CA851E1A"};
`;

export const DeliveryCardTitle = styled.span`
  font-weight: 700;
  color: ${({ itemType }) => (itemType === "E" ? "#2194CD" : "#CA851E")};
  white-space: normal; /* Allows text to wrap to the next line */
  word-wrap: break-word; /* Ensures words break and wrap properly */
`;
export const DeliveryCardTSubTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  color: #0e0e0e;
`;

export const DeliveryInfosContainer = styled.div`
  padding: 0 8px;
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Creates 2 equal-width columns */
  gap: 16px; /* Adds some space between the columns */
`;

export const InfoGroup = styled.div`
  display: flex;
  flex-direction: column;
`;
export const InfoTitle = styled.span`
  font-size: 12px;
  opacity: 0.5;
`;
export const InfoValue = styled.span`
  font-size: 14px;
  white-space: normal; /* Allows text to wrap to the next line */
  word-wrap: break-word; /* Ensures words break and wrap properly */
`;

export const DateLimitToCollectContainer = styled.div`
  padding: 4px 8px;
  background: ${({ bgColor }) => (bgColor ? bgColor : "#d85656")};
  color: #f7f7f7;
  width: 190px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 16px;
  margin-top: -8px;
`;

export const InQueueToSyncWarningContainer = styled.div`
  background: #d856560d;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
`;

export const WarningIconContainer = styled.div`
  background: rgba(216, 86, 86, 0.1);
  padding: 6px;
  border-radius: 4px;
`;

export const WarningText = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #d85656;
  max-width: 100%; /* Set a max-width to allow wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Optional: Adds '...' for overflowing text */
  white-space: normal; /* Allow text to wrap to the next line */
`;
