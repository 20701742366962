import { combineReducers } from "redux";

import auth from "./auth/reducer";
import user from "./user/reducer";
import destinations from "./destinations/reducer";
import geolocation from "./geolocation/reducer";
import queue from "./queue/reducer";
import connection from "./connection/reducer";
import imageviewer from "./imageviewer/reducer";

export default combineReducers({
  auth,
  user,
  destinations,
  geolocation,
  queue,
  connection,
  imageviewer,
});
