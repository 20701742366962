import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-bootstrap/Modal";
import { format } from "date-fns";

import NewObservation from "~/pages/Observations/components/NewObservation";

import { ClearButton } from "~/components/ClearButton";
import { PrimaryButton } from "~/components/PrimaryButton";
import Image from "~/components/Image";
import {
  DeliveryCardTitle,
  DeliveryCardTSubTitle,
} from "../Main/components/CollectCard/styles";
import { formatDate } from "~/utils/treat_date";
import { IoIosArrowBack } from "react-icons/io";
import { CustomDarkButton } from "~/components/CustomButton";

export default function Observation(props) {
  const { item, onHide, show } = props;
  const itemType = item.idTipoEntrega;

  const cardColor = `${itemType === "E" ? "#2194CD" : "#CA851E"}`;
  const [modalNewObservationShow, setModalNewObservationShow] = useState(false);

  function handleShowObservationModal() {
    setModalNewObservationShow(true);
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div
        style={{
          padding: "16px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IoIosArrowBack color={cardColor} size={20} onClick={onHide} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <DeliveryCardTitle itemType={itemType}>
            {item.cdCliente}
            {item.nrDvCliente} - {item.nomeComercialFantasia.nomeFantasia}
          </DeliveryCardTitle>
          <DeliveryCardTSubTitle>
            Emitido em {formatDate(item.dtGeracao)}
          </DeliveryCardTSubTitle>
        </div>
      </div>
      <Modal.Body>
        <p>Observações</p>

        {item.observacao !== null ? (
          item.observacao.map(function(obs) {
            let dtObservacao = obs.dataObservacao;

            if (dtObservacao.indexOf(" ") !== -1) {
              dtObservacao = dtObservacao.replace(" ", "T");
            } else {
              const dt = dtObservacao.substring(0, 10);
              const hr = dtObservacao.substring(11, 19);
              dtObservacao = `${dt}T${hr}`;
            }
            dtObservacao += "-03:00";

            dtObservacao = format(
              new Date(obs.dataObservacao),
              "dd/MM/yyyy HH:mm:ss"
            );
            return (
              <div key={new Date(obs.dataObservacao).valueOf()}>
                {`${dtObservacao} - ${obs.observacao}`}
                {obs.file && typeof obs.file === "string" ? (
                  <Image
                    image={`imagens/${item.cdCliente}/${item.sequencial}/observacoes/${obs.file}`}
                  />
                ) : null}
              </div>
            );
          })
        ) : (
          <div>Nenhuma observação disponível</div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <CustomDarkButton
          height={"40px"}
          text={"Adicionar observação"}
          isSelected={true}
          onClick={() => handleShowObservationModal(true)}
        />
      </Modal.Footer>
      <NewObservation
        item={item}
        show={modalNewObservationShow}
        onHide={() => setModalNewObservationShow(false)}
      />
    </Modal>
  );
}

Observation.propTypes = {
  item: PropTypes.shape({
    nfes: PropTypes.arrayOf(
      PropTypes.shape({
        numero: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      })
    ).isRequired,
    observacao: PropTypes.arrayOf(
      PropTypes.shape({
        observacao: PropTypes.oneOfType([PropTypes.string, PropTypes.string]),
      })
    ),
    enderecoEntrega: PropTypes.shape({
      rua: PropTypes.string.isRequired,
      bairro: PropTypes.string.isRequired,
      cidade: PropTypes.string.isRequired,
      estado: PropTypes.string.isRequired,
      cep: PropTypes.string.isRequired,
      numero: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
        .isRequired,
    }).isRequired,
    nomeComercialFantasia: PropTypes.shape({
      razaoSocial: PropTypes.string.isRequired,
      nomeFantasia: PropTypes.string.isRequired,
    }).isRequired,
    entregue: PropTypes.string.isRequired,
    volumes: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    cnpj: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    cdCliente: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    sequencial: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
  }).isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};
