export const Actions = {
  GET_POSITION: "@geolocation/GET_POSITION",
  GET_POSITION_SUCCESS: "@geolocation/GET_POSITION_SUCCESS",
  GET_POSITION_ERROR: "@geolocation/GET_POSITION_ERROR",
  CLEAR_POSITION: "@geolocation/CLEAR_POSITION",
  GET_WATCH_SUCCESS: "@geolocation/GET_WATCH_SUCCESS",
  GET_WATCH_ERROR: "@geolocation/GET_WATCH_ERROR",
};

export function getCurrentLocationRequest(options = null) {
  return {
    type: Actions.GET_POSITION,
    payload: { options },
  };
}

export function getCurrentLocationSuccess(position) {
  return {
    type: Actions.GET_POSITION_SUCCESS,
    payload: { position },
  };
}

export function getWatchLocationSuccess(position) {
  return {
    type: Actions.GET_WATCH_SUCCESS,
    payload: { position },
  };
}

export function getCurrentLocationError(error) {
  return {
    type: Actions.GET_POSITION_ERROR,
    payload: { error },
  };
}

export function getWatchLocationError(error) {
  return {
    type: Actions.GET_WATCH_ERROR,
    payload: { error },
  };
}

export function clearCurrentLocation() {
  return {
    type: Actions.CLEAR_POSITION,
  };
}
