export const Actions = {
  SHOW_IMAGE: "@imageviewer/SHOW_IMAGE",
};

export function showImage(image) {
  return {
    type: Actions.SHOW_IMAGE,
    payload: { image },
  };
}
