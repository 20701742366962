/* eslint-disable no-param-reassign */
import produce from "immer";
import { Actions } from "./actions";
import { Actions as DestinationsActions } from "../destinations/actions";

const INITIAL_STATE = {
  token: null,
  refreshToken: null,
  signed: false,
  loading: false,
  errorMessage: null,
};

export default function auth(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Actions.SIGN_IN_REQUEST: {
        draft.loading = true;
        break;
      }

      case Actions.SIGN_IN_SUCCESS: {
        draft.token = action.payload.token;
        draft.refreshToken = action.payload.refreshToken;
        draft.signed = true;
        draft.loading = false;
        break;
      }

      case Actions.SIGN_FAILURE: {
        draft.loading = false;
        draft.signed = false;
        draft.errorMessage = action.payload.message;
        break;
      }

      case Actions.SIGN_OUT: {
        draft.token = null;
        draft.refreshToken = null;
        draft.signed = false;
        draft.loading = false;
        draft.errorMessage = null;
        break;
      }

      case DestinationsActions.DESTINATIONS_ERROR: {
        draft.loading = false;
        break;
      }

      default:
    }
  });
}
