import React, { useState } from "react";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import {
  InputContainer,
  InputLabel,
  InputField,
  IconContainer,
  ToggleVisibilityButton,
} from "./styles";

export default function CustomInput({
  label,
  name,
  placeholder,
  Icon,
  type,
  weight,
  isToggleVisibility,
  onChange,
}) {
  const [choosenType, setChoosenType] = useState(type || "text");

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
      <InputContainer>
        <IconContainer>
          <Icon weight={weight} size={20} color={"#97999E"} />
        </IconContainer>
        <InputField
          onChange={onChange}
          style={{
            borderRadius: "8px",
            border: "2px solid #7E7E7E",
            paddingLeft: "40px",
          }}
          name={name}
          placeholder={placeholder}
          type={choosenType}
        />
        {isToggleVisibility && (
          <ToggleVisibilityButton
            onClick={() =>
              setChoosenType(prev => (prev === "text" ? "password" : "text"))
            }
          >
            {choosenType === "text" ? <FaEyeSlash /> : <FaEye />}
          </ToggleVisibilityButton>
        )}
      </InputContainer>
    </div>
  );
}
