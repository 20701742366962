/* eslint-disable no-param-reassign */
import produce from "immer";
import { Actions as AuthActions } from "~/store/modules/auth/actions";
import { Actions } from "./actions";

const INITIAL_STATE = {
  queue: [],
  isLoading: false,
};

export default function queue(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Actions.ADD_TO_QUEUE: {
        const item = draft.queue.find(
          delivery => delivery.sequencial === action.payload.delivery.sequencial
        );
        if (item === undefined) {
          draft.queue = [...draft.queue, action.payload.delivery];
        }
        break;
      }
      case Actions.REMOVE_FROM_QUEUE: {
        if (draft.queue.length === 1) {
          draft.queue = [];
        } else {
          const item = draft.queue.find(
            delivery =>
              delivery.sequencial === action.payload.delivery.sequencial
          );
          if (item !== undefined) {
            draft.queue = draft.queue.splice(draft.queue.indexOf(item), 1);
          }
        }
        break;
      }
      case AuthActions.SIGN_OUT: {
        draft.queue = [];
        break;
      }
      case Actions.IS_LOADING_SEND: {
        draft.isLoading = action.payload;
        break;
      }
      default:
    }
  });
}
