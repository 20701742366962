import styled from "styled-components";

export const Img = styled.img`
  display: block;
  cursor: pointer;
  width: ${props => (props.width ? `${props.width}px` : "auto")};
  height: auto;
`;

export const Wrapper = styled.div`
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
`;

export const ImgContainer = styled.div`
  display: block;
  width: auto;
  height: auto;
`;
