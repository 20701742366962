/* eslint-disable no-param-reassign */
import { all, put, call, takeLatest } from "redux-saga/effects";
import { getCurrentLocation } from "~/services/getCurrentLocation";
import {
  Actions,
  getCurrentLocationError,
  getCurrentLocationSuccess,
} from "./actions";

export function* GetCurrentLocation({ payload }) {
  if (!navigator.geolocation) {
    yield put(
      getCurrentLocationError({
        code: -1,
      })
    );
  } else {
    try {
      const data = yield call(getCurrentLocation, payload);
      yield put(getCurrentLocationSuccess(data));
    } catch (error) {
      yield put(getCurrentLocationError(error));
    }
  }
}

export default all([takeLatest(Actions.GET_POSITION, GetCurrentLocation)]);
