import { takeLatest, call, put, all, select } from "redux-saga/effects";
import jwt from "jsonwebtoken";

import history from "~/services/history";
import api from "~/services/api";
import { Destinations } from "~/store/modules/destinations/sagas";
import { sendToSentry } from "~/sentry";

import {
  signInSuccess,
  signFailure,
  redirectToMain,
  Actions,
  signOut,
} from "./actions";

export function* Login({ payload }) {
  try {
    const { login, password } = payload;

    const response = yield call(api.post, "/new-session", {
      nm_login: login,
      ds_password: password,
    });

    if (response.status === 200) {
      const { token, refreshToken } = response.data;

      api.defaults.headers.Authorization = `Bearer ${token}`;

      const userId = jwt.decode(token).uid;
      const userData = yield call(api.get, "/user-data", {
        params: { cdEntregador: userId },
      });

      const { isTransportador } = userData.data;
      const isTransporter = isTransportador === "true";
      const user = { userId, isTransporter, vehicle: 0 };

      yield put(signInSuccess(token, refreshToken, user));

      if (isTransportador === "true") {
        history.push("/transporter");
      } else {
        yield put(redirectToMain());
        history.push("/geolocation");
      }
    } else {
      yield put(signFailure("Login ou Senha inválido!"));
    }
  } catch (err) {
    const sentryMessage = {
      message: "error on *Login",
      error: err.message,
      jsonError: JSON.stringify(err),
    };
    sendToSentry(sentryMessage, true);
    let message = "Ocorreu um erro inesperado : ";

    if (err.response) {
      if (err.response.data) {
        if (err.response.data.message) {
          message = err.response.data.message;
        } else {
          message += err.response.data.problem;
        }
      }
    } else {
      message += "Servidor indisponível";
    }
    yield put(signFailure(message));
  }
}

export function* CheckToken() {
  try {
    const { token } = yield select(state => state.auth);
    const { user } = yield select(state => state.user);

    api.defaults.headers.Authorization = `Bearer ${token}`;

    const response = yield call(api.get, "/user-data", {
      params: { cdEntregador: user.userId },
    });
    if (response.status !== 200) {
      yield put(signOut());
    }
  } catch (err) {
    const sentryMessage = {
      message: "error on *CheckToken",
      error: err.message,
      jsonError: JSON.stringify(err),
    };
    sendToSentry(sentryMessage, true);
    yield put(signOut());
  }
}

export function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function SignOut() {
  history.push("/");
}

export function* getDestinationList() {
  yield call(Destinations);
}

export default all([
  takeLatest("persist/REHYDRATE", setToken),
  takeLatest(Actions.SIGN_IN_REQUEST, Login),
  takeLatest(Actions.SIGN_OUT, SignOut),
  takeLatest(Actions.REDIRECT_TO_MAIN, getDestinationList),
  takeLatest(Actions.CHECK_TOKEN, CheckToken),
]);
