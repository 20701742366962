import styled, { css, keyframes } from "styled-components";

const flashAnimation = keyframes`
  from {
    opacity: 0.75;
  }

  to {
    opacity: 0;
  }
`;

export const VideoWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
`;

export const CenterDiv = styled.div`
  width: auto;
  height: auto;
  max-height: ${props => (props.maxHeight ? `${props.maxHeight}px` : "100vh")};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  justify-content: center;
`;

export const ButtonsDiv = styled.div`
  width: 100%;
  height: 10%;
  margin-top: -65px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  & > * {
    margin: 0.25rem;
  }
  z-index: 5000;
`;

export const Video = styled.video`
  width: auto;
  height: auto;
  max-height: ${props => (props.maxHeight ? `${props.maxHeight}px` : "100vh")};
`;

export const Canvas = styled.canvas`
  width: auto;
  height: auto;
  max-height: ${props => (props.maxHeight ? `${props.maxHeight}px` : "100vh")};
`;

export const Flash = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: #ffffff;

  ${({ flash }) => {
    if (flash) {
      return css`
        animation: ${flashAnimation} 750ms ease-out;
      `;
    }
    return false;
  }}
`;

export const RoundButton = styled.button`
  border-radius: 100%;
  font-weight: bold;
  color: ${props => (props.primary ? "#fff" : "rgba(255, 255, 255,0.7)")};
  background-color: rgba(255, 255, 255, 0.3);
  padding: ${props => (props.squareIcon ? "5px 8px 9px" : "2px")};
  border: 2px solid
    ${props => (props.primary ? "#fff" : "rgba(255, 255, 255,0.7)")};
  margin: 0.25rem 1rem;
`;

export const ErrorMessageWrapper = styled.div`
  margin-top: 15px;
`;

export const ErrorMessage = styled.p`
  font-weight: bold;
  color: #c00;
  margin: 0;
`;
export const AdditionalErrorMessage = styled.p`
  color: #c00;
  margin: 0;
  font-size: 0.9em;
`;
