import styled from "styled-components";

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 16px; /* Adjust as needed for spacing */
`;

export const InputLabel = styled.label`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;
  color: #252525;
`;

export const InputField = styled.input`
  display: flex;
  padding: 10px 40px 10px 70px;
  width: 298px;
  border: 2px solid #ccc;
  border-radius: 30px;
  font-size: 16px; /* Base size */
  &:focus {
    border-color: #4a90e2; /* Focus effect */
  }

  &::placeholder {
    font-weight: 300;
    color: #97999e; /* Change the placeholder color if needed */
    opacity: 1; /* Ensures full opacity for better visibility */
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  left: 15px; /* Positioning the icon */
  display: flex;
  align-items: center;
  pointer-events: none; /* Prevents click events on the icon */
`;

export const ToggleVisibilityButton = styled.div`
  color: #97999e;
  cursor: pointer;
  position: absolute;
  right: 10px; /* Position for the toggle visibility icon */
  display: flex;
  align-items: center;
  pointer-events: auto; /* Allows click events on the icon */
`;
