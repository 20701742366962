import styled from "styled-components";
import CrosshairIcon from "~/assets/images/icons/Crosshair.svg";

export const GeoLocationIcon = styled.img.attrs({
  src: CrosshairIcon,
})`
  color: #2194cd;
`;
export const GeoLocationContainer = styled.div`
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const GeoLocationIconContainer = styled.div`
  display: flex;
  justify-content: center;
  hight: 48px;
  width: 48px;
  background-color: #2194cd1a;
  border-radius: 8px;
  padding: 12px;
`;

export const GeoLocationTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  color: #2194cd;
  margin-top: 16px;
`;

export const GeoLocationDescription = styled.span`
  font-size: 14px;
  text-align: center;
  color: #0e0e0e;
  width: 250px;
`;

export const GeoLocationButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  justify-content: space-between;
  margin-top: 16px;
`;
