import { createTransform } from "redux-persist";

const SetTransform = createTransform(
  inboundState => {
    return { ...inboundState, loading: false };
  },
  outboundState => {
    return { ...outboundState, loading: false };
  },
  { whitelist: ["auth", "destinations"] }
);

export default SetTransform;
