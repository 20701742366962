import { toast } from "react-toastify";
import { setDelivered } from "../destinations/actions";
import { sendToSentry } from "~/sentry";

export const Actions = {
  ADD_TO_QUEUE: "@queue/ADD_TO_QUEUE",
  REMOVE_FROM_QUEUE: "@queue/REMOVE_FROM_QUEUE",
  FORCE_SEND: "@queue/FORCE_SEND",
  IS_LOADING_SEND: "@queue/IS_LOADING_SENDING_QUEUE",
  FORCE_QUEUE_CHECK: "@queue/FORCE_QUEUE_CHECK",
};

export function deliveredPackageAction(delivery, dispatch, isConnected) {
  try {
    addDeliveryToQueue(delivery, dispatch);
  } catch (err) {
    const sentryMessage = {
      message: "error on deliveredPackageAction",
      error: err.message,
    };
    sendToSentry(sentryMessage, isConnected);
    toast.error("Não efetuou as entregas");
  }
}

export function addToQueue(delivery) {
  return {
    type: Actions.ADD_TO_QUEUE,
    payload: { delivery },
  };
}

export function setLoadingSendingQueue(loading) {
  return {
    type: Actions.IS_LOADING_SEND,
    payload: loading,
  };
}

export function addDeliveryToQueue(delivery, dispatch) {
  dispatch(addToQueue(delivery));
  dispatch(setDelivered(delivery));
  dispatch(forceQueueCheck());
}
export function removeFromQueue(delivery) {
  return {
    type: Actions.REMOVE_FROM_QUEUE,
    payload: { delivery },
  };
}

export function forceSend(delivery) {
  return {
    type: Actions.FORCE_SEND,
    payload: { delivery },
  };
}
export function forceQueueCheck() {
  return {
    type: Actions.FORCE_QUEUE_CHECK,
  };
}
