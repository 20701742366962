import React from "react";

import packageJson from "../../../package.json";
import { Container } from "./styles";

export default function Footer() {
  return (
    <Container>
      <span style={{ color: "#2194CD", fontWeight: 400, fontSize: "16px" }}>
        Versão {packageJson.version}
      </span>
    </Container>
  );
}
