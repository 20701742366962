import styled from "styled-components";

export const PrimaryButton = styled.button`
  border: 0;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  background-color: #143c96;
  padding: 5px 10px;
`;
