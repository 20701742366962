/* eslint-disable no-param-reassign */
import produce from "immer";

import { Actions as AuthActions } from "~/store/modules/auth/actions";

import { Actions } from "./actions";

const INITIAL_STATE = {
  destinationsToDeliver: { toDeliver: [] },
  destinationsDelivered: { delivered: [] },
  destinationsInfo: {
    info: { qtEntregas: "0/0", qtVolumes: "0/0", qtIntervaloRegistroGPS: 0 },
  },
  loading: false,
  loadingSendFile: false,
  errorMessage: null,
  sendFileReturn: null,
};

export default function destinations(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Actions.UPDATE_DESTINATIONS_INFO: {
        let qtVolumes = 0;
        let qtEntregas = 0;

        draft.destinationsDelivered.delivered.map(function updInfo(
          delivery,
          index
        ) {
          qtVolumes += delivery.volumes;
          qtEntregas = index + 1;
          return { qtVolumes, qtEntregas };
        });

        const qtEntregaOriginal = draft.destinationsInfo.info.qtEntregas.substr(
          draft.destinationsInfo.info.qtEntregas.indexOf("/") + 1
        );

        const qtVolumesOriginal = draft.destinationsInfo.info.qtVolumes.substr(
          draft.destinationsInfo.info.qtVolumes.indexOf("/") + 1
        );

        const qtIntervaloRegistroGPSOriginal = draft.destinationsInfo.info.qtIntervaloRegistroGPS.substr(
          draft.destinationsInfo.info.qtIntervaloRegistroGPS.indexOf("/") + 1
        );

        draft.destinationsInfo = {
          info: {
            qtEntregas: `${qtEntregas}/${qtEntregaOriginal}`,
            qtVolumes: `${qtVolumes}/${qtVolumesOriginal}`,
            qtIntervaloRegistroGPS: qtIntervaloRegistroGPSOriginal,
          },
        };
        break;
      }

      case Actions.SET_NEW_OBSERVATION: {
        let item = null;

        item = draft.destinationsDelivered.delivered.find(
          delivery => delivery.sequencial === action.payload.delivery.sequencial
        );

        if (item !== undefined) {
          draft.destinationsDelivered.delivered.splice(
            draft.destinationsDelivered.delivered.indexOf(item),
            1,
            action.payload.delivery
          );

          draft.destinationsDelivered.delivered = [
            ...draft.destinationsDelivered.delivered,
          ];
        } else {
          item = draft.destinationsToDeliver.toDeliver.find(
            delivery =>
              delivery.sequencial === action.payload.delivery.sequencial
          );

          if (item !== undefined) {
            draft.destinationsToDeliver.toDeliver.splice(
              draft.destinationsToDeliver.toDeliver.indexOf(item),
              1,
              action.payload.delivery
            );

            draft.destinationsToDeliver.toDeliver = [
              ...draft.destinationsToDeliver.toDeliver,
            ];
          }
        }
        draft.loading = false;
        draft.errorMessage = null;
        break;
      }

      case AuthActions.REDIRECT_TO_MAIN: {
        draft.loading = true;
        draft.errorMessage = null;
        break;
      }

      case Actions.DESTINATIONS_TO_DELIVER: {
        draft.destinationsToDeliver = action.payload;
        draft.loading = false;
        draft.errorMessage = null;
        break;
      }

      case Actions.DESTINATIONS_DELIVERED: {
        draft.destinationsDelivered = action.payload;
        draft.loading = false;
        draft.errorMessage = null;
        break;
      }

      case Actions.DESTINATIONS_INFO: {
        draft.destinationsInfo = action.payload;
        draft.loading = false;
        draft.errorMessage = null;
        break;
      }

      case Actions.SET_DELIVERED: {
        let item = null;

        item = draft.destinationsDelivered.delivered.find(
          delivery => delivery.sequencial === action.payload.delivery.sequencial
        );

        if (item === undefined) {
          draft.destinationsDelivered.delivered = [
            ...draft.destinationsDelivered.delivered,
            action.payload.delivery,
          ];

          item = draft.destinationsToDeliver.toDeliver.find(
            delivery =>
              delivery.sequencial === action.payload.delivery.sequencial
          );

          if (item !== undefined) {
            draft.destinationsToDeliver.toDeliver.splice(
              draft.destinationsToDeliver.toDeliver.indexOf(item),
              1
            );
          }
        } else {
          item = draft.destinationsToDeliver.toDeliver.find(
            delivery =>
              delivery.sequencial === action.payload.delivery.sequencial
          );

          if (item !== undefined) {
            draft.destinationsToDeliver.toDeliver.splice(
              draft.destinationsToDeliver.toDeliver.indexOf(item),
              1
            );
          }
        }
        draft.loading = false;
        draft.errorMessage = null;
        break;
      }

      case Actions.DESTINATIONS_ERROR: {
        draft.loading = false;
        break;
      }

      case Actions.SEND_FILE: {
        draft.loadingSendFile = true;
        break;
      }

      case Actions.SET_LOADING_SEND_FILE: {
        draft.loadingSendFile = action.payload;
        break;
      }

      case Actions.SET_SEND_FILE_RETURN: {
        draft.sendFileReturn = action.payload;
        break;
      }

      case AuthActions.SIGN_OUT: {
        draft.destinationsToDeliver = { toDeliver: [] };
        draft.destinationsDelivered = { delivered: [] };
        draft.destinationsInfo = {
          info: {
            qtEntregas: "0/0",
            qtVolumes: "0/0",
            qtIntervaloRegistroGPS: 0,
          },
        };
        break;
      }

      default:
    }
  });
}
