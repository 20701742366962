/* eslint-disable no-param-reassign */
import produce from "immer";
import { Actions } from "./actions";

const INITIAL_STATE = {
  fetched: false,
  fetching: false,
  geolocationAvailable: true,
  geolocationEnabled: false,
  coords: null,
  error: null,
  lastCoordsDate: null,
  lastCoords: null,
};

export default function geolocation(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Actions.GET_POSITION:
        draft.fetched = false;
        draft.fetching = true;
        draft.error = null;
        break;
      case Actions.GET_POSITION_SUCCESS:
        draft.fetched = true;
        draft.fetching = false;
        draft.geolocationEnabled = true;
        draft.coords = action.payload.position.coords;
        draft.error = null;
        break;
      case Actions.GET_POSITION_ERROR:
        draft.fetched = true;
        draft.fetching = false;
        draft.geolocationAvailable = action.payload.error.code !== 0;
        draft.geolocationEnabled =
          draft.geolocationAvailable && action.payload.error.code !== 1;
        draft.error = draft.geolocationEnabled
          ? "Houve um erro ao buscar sua localização"
          : "É necessario habilitar a localização";
        break;
      case Actions.CLEAR_POSITION:
        draft.coords = null;
        draft.fetched = false;
        draft.error = null;
        draft.fetching = false;
        break;
      case Actions.GET_WATCH_SUCCESS:
        draft.lastCoordsDate = new Date();
        draft.lastCoords = action.payload.position.coords;
        break;
      default:
    }
  });
}
