/* eslint-disable no-param-reassign */
import produce from "immer";
import { Actions } from "./actions";

const INITIAL_STATE = {
  Connected: false,
};

export default function connection(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case Actions.CONNECTION_STATUS: {
        draft.Connected = action.payload.status;
        break;
      }
      default:
    }
  });
}
