import styled from "styled-components";
import { FaArrowLeft } from "react-icons/fa";

export const BarCodeReaderWrapper = styled.div`
  display: block;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Video = styled.video`
  min-width: 100%;
  min-height: 100%;

  width: auto;
  height: auto;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const BackIcon = styled(FaArrowLeft)`
  position: absolute;
  top: 5px;
  left: 5px;
  color: #fff;
  z-index: 200;
  cursor: pointer;
  height: 32px;
  width: 32px;
  color: #fff;
`;

export const LastBarcode = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 42px;
  font-size: 1.2em;
  line-height: 42px;
  vertical-align: middle;
  z-index: 100;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  padding-left: 50px;
`;

export const ErrorMessage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 42px;
  font-size: 1.2em;
  line-height: 42px;
  vertical-align: middle;
  z-index: 100;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  padding-left: 50px;
`;
