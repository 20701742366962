export const Actions = {
  SIGN_IN_REQUEST: "@auth/SIGN_IN_REQUEST",
  SIGN_IN_SUCCESS: "@auth/SIGN_IN_SUCCESS",
  SIGN_FAILURE: "@auth/SIGN_FAILURE",
  SIGN_OUT: "@auth/SIGN_OUT",
  REDIRECT_TO_MAIN: "@auth/REDIRECT_TO_MAIN",
  CHECK_TOKEN: "@auth/CHECK_TOKEN",
};

export function signInRequest(login, password) {
  return {
    type: Actions.SIGN_IN_REQUEST,
    payload: { login, password },
  };
}

export function signInSuccess(token, refreshToken, user) {
  return {
    type: Actions.SIGN_IN_SUCCESS,
    payload: { token, refreshToken, user },
  };
}

export function signFailure(message) {
  return {
    type: Actions.SIGN_FAILURE,
    payload: { message },
  };
}

export function signOut() {
  return {
    type: Actions.SIGN_OUT,
  };
}

export function redirectToMain() {
  return {
    type: Actions.REDIRECT_TO_MAIN,
  };
}

export function checkToken() {
  return {
    type: Actions.CHECK_TOKEN,
  };
}
