import React, { useEffect, useState } from "react";
import { Tabs } from "react-tabs";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  ToDeliver,
  Delivered,
  DeliveryList,
  OffLineFooter,
  OfflineText,
  HeaderTabs,
  HeaderTab,
  CustomSwipeableViews,
  TabsAndListContainer,
  TabsContainer,
} from "./styles";
import DeliveryCard from "./components/DeliveryCard";
import { WatchLocationManager } from "~/components/WatchLocation";

import {
  getWatchLocationSuccess,
  getWatchLocationError,
} from "~/store/modules/geolocation/actions";

import ImageViewer from "../../components/ImageViewer/index";
import Offline from "~/components/connection/Offline";
import { filterDelivery } from "./helper";
import HeaderComponent from "./components/Header";
import CustomButton from "~/components/CustomButton";
import CollectCard from "./components/CollectCard";

export default function Main() {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [filteredDelivered, setFilteredDelivered] = useState([]);
  const [filteredToDeliver, setFilteredToDeliver] = useState([]);

  const { toDeliver } = useSelector(
    state => state.destinations.destinationsToDeliver
  );

  const { delivered } = useSelector(
    state => state.destinations.destinationsDelivered
  );

  const [index, setIndex] = useState(0);

  useEffect(() => {
    const filteredDelivered = filterDelivery(delivered, search);
    const filteredToDeliver = filterDelivery(toDeliver, search);
    setFilteredDelivered(filteredDelivered);
    setFilteredToDeliver(filteredToDeliver);
  }, [search, delivered, toDeliver]);

  function handleChangeIndex(newIndex) {
    setIndex(newIndex);
  }

  function handleSelect(newIndex) {
    setIndex(newIndex);
  }

  function watchSuccess(position) {
    dispatch(getWatchLocationSuccess(position));
  }

  function watchError(positionError) {
    dispatch(getWatchLocationError(positionError));
  }

  function handleSearch(e) {
    setSearch(e.target.value);
  }

  return (
    <Container>
      <HeaderComponent search={search} handleSearch={handleSearch} />
      <TabsAndListContainer>
        <TabsContainer>
          <CustomButton
            width={"50%"}
            text={"Entregar | Recolher"}
            isSelected={index === 0}
            onClick={() => {
              handleSelect(0);
            }}
          />
          <CustomButton
            isSelected={index === 1}
            onClick={() => {
              handleSelect(1);
            }}
            width={"50%"}
            text={"Finalizados"}
          />
        </TabsContainer>
        <div>
          {index === 0 ? (
            <DeliveryList>
              <div>
                {filteredToDeliver.map(toDeliverItem => {
                  return (
                    <CollectCard
                      key={toDeliverItem.sequencial}
                      item={toDeliverItem}
                    />
                  );
                })}
              </div>
            </DeliveryList>
          ) : index === 1 ? (
            <DeliveryList>
              <div>
                {filteredDelivered.map(deliveredItem => (
                  <CollectCard
                    key={deliveredItem.sequencial}
                    item={deliveredItem}
                  />
                ))}
              </div>
            </DeliveryList>
          ) : null}
        </div>
      </TabsAndListContainer>

      <Offline>
        <OffLineFooter>
          <OfflineText>
            Você está offline, mas pode continuar efetuando suas entregas. Assim
            que conectar será sincronizado com o servidor.
          </OfflineText>
        </OffLineFooter>
      </Offline>
      <WatchLocationManager success={watchSuccess} error={watchError} />
      <ImageViewer />
    </Container>
  );
}
