export function getCurrentLocation(userOptions) {
  const options = userOptions || {};
  options.timeout = options.timeout || 10000;
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        resolve(position);
      },
      error => {
        reject(error);
      },
      options
    );
  });
}
