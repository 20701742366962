import styled from "styled-components";

export const CustomButtonContainer = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 4px;
  color: #ededed;
  font-weight: 500;
  font-size: 14px;
  height: ${({ height }) => (height ? height : "36px")};
  width: ${({ width }) => width || "100%"};

  /* Remove default button styles */
  background: none;
  border: none;
  outline: none;

  /* Custom styles */
  color: ${({ isSelected }) => (isSelected ? "#ededed" : "#2194CD")};
  background-color: ${({ isSelected, bgColor }) =>
    isSelected ? (bgColor ? bgColor : "#2194CD") : "transparent"};

  &:focus {
    outline: none;
  }
`;
export const CustomDarkButtonContainer = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  height: ${({ height }) => (height ? height : "48px")};
  width: ${({ width }) => width || "100%"};
  color: #fafafa;

  /* Remove default button styles */
  background: none;
  border: none;
  outline: none;

  /* Custom styles */
  color: ${({ isSelected }) => (isSelected ? "#FAFAFA" : "#143C96")};
  background-color: ${({ isSelected }) =>
    isSelected ? "#30343E" : "transparent"};

  &:focus {
    outline: none;
  }
`;
