import React, { useState } from "react";
import { FaBarcode } from "react-icons/fa";
import RefreshIcon from "~/assets/images/icons/ArrowsCounterClockwise.svg";
import LogoutIcon from "~/assets/images/icons/SignOut.svg";
import { Menu, MenuLink } from "../../styles";
import history from "~/services/history";

import { signOut, redirectToMain } from "~/store/modules/auth/actions";
import packageJson from "../../../../../package.json";
import Online from "~/components/connection/Online";
import Offline from "~/components/connection/Offline";
import { useDispatch, useSelector } from "react-redux";
import {
  DataLine,
  Delivery,
  HeaderContainer,
  Input,
  InputContainer,
  OnlineBubbleContainer,
  PageTitle,
  SearchContainer,
  SearchIcon,
  TitleLine,
  TitleVersionContainer,
  Vehicle,
  VersionSpan,
  Volumes,
  VolumesQuantity,
} from "./styles";

export default function HeaderComponent({ handleSearch, search }) {
  const dispatch = useDispatch();

  const [_, setOnline] = useState(true);
  const { vehicle } = useSelector(state => state.user.user);

  const { queue } = useSelector(states => states.queue);
  const { isTransporter, userId } = useSelector(state => state.user.user);
  const connected = useSelector(states => states.connection.Connected);
  const { qtEntregas, qtVolumes } = useSelector(
    state => state.destinations.destinationsInfo.info
  );
  const showVehicle = vehicle !== 0;
  const pendingItem = queue.length > 0;

  function handleBarCode() {
    history.push("/barcode");
  }

  function handleRefresh() {
    if (!pendingItem) {
      dispatch(redirectToMain());
    }
  }

  function handleSignOut() {
    if (!pendingItem) {
      dispatch(signOut());
    }
  }

  return (
    <HeaderContainer>
      <TitleLine>
        <TitleVersionContainer>
          <PageTitle>Lista de Entregas</PageTitle>
          <VersionSpan>Versão {packageJson.version}</VersionSpan>
        </TitleVersionContainer>
        <Menu>
          {isTransporter && (
            <MenuLink>
              <FaBarcode onClick={handleBarCode} />
            </MenuLink>
          )}
          <Online onChange={setOnline}>
            <MenuLink disabled={pendingItem}>
              <img
                style={{
                  opacity: `${pendingItem ? 0.5 : 1}`,
                }}
                src={RefreshIcon}
                onClick={handleRefresh}
              />
            </MenuLink>
            <MenuLink disabled={pendingItem}>
              <img
                style={{
                  opacity: `${pendingItem ? 0.5 : 1}`,
                }}
                src={LogoutIcon}
                onClick={handleSignOut}
              />
            </MenuLink>
          </Online>
          <Offline>
            <MenuLink>
              <img
                style={{
                  opacity: `${pendingItem ? 0.5 : 1}`,
                }}
                src={RefreshIcon}
              />
            </MenuLink>
            <MenuLink>
              <img
                style={{
                  opacity: `${pendingItem ? 0.5 : 1}`,
                }}
                src={LogoutIcon}
                onClick={handleSignOut}
              />
            </MenuLink>
          </Offline>
        </Menu>
      </TitleLine>
      <div>
        <Volumes>
          <Delivery>Código do Entregador • {userId}</Delivery>
          {showVehicle ? <Vehicle>Placa • {vehicle} </Vehicle> : ""}
          <Delivery>Entregas realizadas • {qtEntregas}</Delivery>
          <DataLine>
            <VolumesQuantity>Volumes entregues • {qtVolumes}</VolumesQuantity>
            <OnlineBubbleContainer isConnected={connected}>
              <span>FILA</span>
              <span>{queue.length}</span>
            </OnlineBubbleContainer>
          </DataLine>
        </Volumes>
      </div>
      <SearchContainer>
        <InputContainer>
          <SearchIcon size={20} />
          <Input
            placeholder="número da nota fiscal"
            type="text"
            onChange={e => handleSearch(e)}
            value={search}
          />
        </InputContainer>
      </SearchContainer>
    </HeaderContainer>
  );
}
