import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";

import { getCurrentLocationRequest } from "~/store/modules/geolocation/actions";
import { PrimaryButton } from "~/components/PrimaryButton";
import history from "~/services/history";
import {
  GeoLocationButtonsContainer,
  GeoLocationContainer,
  GeoLocationDescription,
  GeoLocationIcon,
  GeoLocationIconContainer,
  GeoLocationTitle,
} from "./styles";
import CustomButton, { CustomDarkButton } from "~/components/CustomButton";
import { CustomDarkButtonContainer } from "~/components/CustomButton/styles";

export default function Geolocation() {
  const dispatch = useDispatch();
  const { fetched, error } = useSelector(states => states.geolocation);

  const geolocationEnabled = useSelector(
    states => states.geolocation.geolocationEnabled
  );

  if (error) {
    // toast(error, {
    //   hideProgressBar: true,
    // });
  }

  if (fetched || geolocationEnabled) {
    history.push("/main");
  }

  function handleActivateGeolocation() {
    dispatch(getCurrentLocationRequest());
  }

  function handleNoGeoLocation() {
    history.push("/main");
  }

  return (
    !fetched &&
    !geolocationEnabled && (
      <Modal show centered>
        <GeoLocationContainer>
          <GeoLocationIconContainer>
            <GeoLocationIcon size={25} />
          </GeoLocationIconContainer>
          <GeoLocationTitle>Habilite a Geolocalização</GeoLocationTitle>
          <GeoLocationDescription>
            O aplicativo precisa do acesso a localização. Clique no botão para
            habilitar e logo em seguida no bbotão permitir para que o aplicativo
            tenha acesso a sua localização
          </GeoLocationDescription>
          <GeoLocationButtonsContainer>
            {/* <CustomDarkButton
              text={"Cancelar"}
              isSelected={true}
              onClick={handleNoGeoLocation}
            /> */}
            <CustomButton
              text={"Ativar localização"}
              isSelected={true}
              onClick={handleActivateGeolocation}
            />
          </GeoLocationButtonsContainer>
        </GeoLocationContainer>
      </Modal>
    )
  );
}
