import { createGlobalStyle } from "styled-components";
import "react-toastify/dist/ReactToastify.css";

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    min-height: 100%;
  }

  body {
    background: #30343e;
    -webkit-font-smoothing: antialiased !important;
  }

  body, input, button {
    color: #222;
    font-size: 14px;
    font-family: Poppins, Arial, Helvetica, sans-serif;
  }

  button {
    cursor: pointer;
  }

  .toast {
    & div {
      background-color: #333;
      color: #fff;
      & .Toastify__close-button--default {
        color: #fff;
        opacity: 0.7;
      }
    }
  }

  div.fade.modal.show + div.fade.modal-backdrop.show {
    z-index: 1060;
  }
`;
