import { useState, useEffect } from "react";
import { toast } from "react-toastify";

export function useUserMedia({ requestedMedia, onError }) {
  const [mediaStream, setMediaStream] = useState(null);

  useEffect(() => {
    async function enableStream() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(
          requestedMedia
        );
        setMediaStream(stream);
      } catch (err) {
        if (onError) {
          onError("Não foi possível acessar a câmera");
        }
        toast.error("Não foi possível acessar a câmera");
      }
    }

    if (!mediaStream) {
      enableStream();
    }
  }, [mediaStream, requestedMedia, onError]);
  return mediaStream;
}
