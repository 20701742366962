import React from "react";
import { CustomButtonContainer, CustomDarkButtonContainer } from "./styles";

export default function CustomButton({
  width,
  text,
  isSelected,
  onClick,
  height,
  bgColor,
}) {
  return (
    <CustomButtonContainer
      bgColor={bgColor}
      height={height}
      onClick={onClick}
      width={width}
      isSelected={isSelected}
    >
      <span>{text}</span>
    </CustomButtonContainer>
  );
}
export function CustomDarkButton({
  width,
  text,
  isSelected,
  onClick,
  rightLogo,
  type,
  isDisabled,
  height,
}) {
  return (
    <CustomDarkButtonContainer
      height={height}
      disabled={isDisabled}
      type={type}
      onClick={onClick}
      width={width}
      isSelected={isSelected}
    >
      <span>{text}</span>
      {rightLogo ? rightLogo : null}
    </CustomDarkButtonContainer>
  );
}
