import styled from "styled-components";
import { Tab, TabList } from "react-tabs";
import SwipeableViews from "react-swipeable-views";

export const Container = styled.div``;

export const Header = styled.div`
  background-color: #143c96;
  color: #fff;
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
`;

export const MenuLink = styled.span`
  cursor: pointer;
  text-decoration: none;
  color: #fff;
  font-size: 1.1em;
  margin-left: 24px;
  text-align: center;
`;

export const FilaOffline = styled.div`
  padding: 0 10px;
`;

export const ToDeliver = styled.div`
  padding: 10px 0;
`;

export const Delivered = styled.div`
  padding: 10px 0;
`;

const ConnectionIndicator = styled.div`
  margin-left: 0.3em;
  width: 0.9em;
  height: 0.9em;
  border-radius: 50%;
  display: inline-block;
`;

export const OnlineIndicator = styled(ConnectionIndicator)`
  background-color: #03fc17;
`;
export const OfflineIndicator = styled(ConnectionIndicator)`
  background-color: #ff0808;
`;

export const OffLineFooter = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  color: #fff;
  background-color: #e37a7a;
`;

export const OfflineText = styled.p`
  text-align: center;
  padding: 5px;
`;

export const DeliveryList = styled.div`
  padding-bottom: 100px;
`;

export const HeaderTabs = styled(TabList)`
  display: flex;
  font-size: 1.1em;
  width: 100%;
  background-color: #143c96;
  box-shadow: inset 0 8px 5px -7px rgba(0, 0, 0, 0.5);
  margin-bottom: 0;FaSearch
`;

export const HeaderTab = styled(Tab)`
  width: 50%;
  list-style-type: none;
  text-align: center;
  color: #fff;
  margin-bottom: 2px;

  &.active {
    margin: 0;
    border-bottom: #fff solid 2px;
  }
`;

export const CustomSwipeableViews = styled(SwipeableViews)`
  box-shadow: inset 0 8px 5px -7px rgba(0, 0, 0, 0.5);
`;

export const TabsAndListContainer = styled.div`
  padding: 14px 8px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: #ededed;
  min-height: 100dvh;
`;
export const TabsContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #2194cd;
  border-radius: 8px;
  padding: 8px;
  height: 52px;
  margin-bottom: 14px;
`;
