import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { ApmRoute } from "@elastic/apm-rum-react";

import { store } from "~/store";

export default function RouteWrapper({
  component: Component,
  isPrivate,
  ...rest
}) {
  const { signed } = store.getState().auth;
  const { isTransporter, vehicle } = store.getState().user;

  if (!signed && isPrivate) {
    return <Redirect to="/" />;
  }

  if (signed && !isPrivate) {
    if (isTransporter && !vehicle) {
      return <Redirect to="/transporter" />;
    }
    return <Redirect to="/main" />;
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <ApmRoute {...rest} component={Component} />;
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};
