export const Actions = {
  CONNECTION_STATUS: "@connection/CONNECTION_STATUS",
};

export function setConnectionStatus(status) {
  return {
    type: Actions.CONNECTION_STATUS,
    payload: { status },
  };
}
