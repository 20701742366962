import React, { useState } from "react";
import { PrimaryButton } from "~/components/PrimaryButton";
import Image from "~/components/Image";
import { PreviewImg } from "~/components/PreviewImg";
import { Img } from "~/components/Image/style";
import { CardActions, CardContent } from "../DeliveryCard/styles";
import { useDispatch, useSelector } from "react-redux";
import { sendFile } from "~/store/modules/destinations/actions";

export const ImageArea = ({ item }) => {
  const dispatch = useDispatch();

  const [picture, setPicture] = useState("");
  const [pictureUrl, setPictureUrl] = useState("");

  const { queue } = useSelector(state => state.queue);
  const { loadingSendFile } = useSelector(state => state.destinations);

  const onQueueItem = queue.find(
    delivery => delivery.sequencial === item.sequencial
  );

  const onQueue = onQueueItem !== undefined;

  const fileToDataUri = file =>
    new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = event => {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  function onFileUpload(e) {
    const file = e.target.files[0];
    if (!file) {
      setPictureUrl("");
      return;
    }

    const filename = `cade-${Math.floor(Date.now() / 1000)}.png`;

    setPicture({ file, filename });
    fileToDataUri(file).then(dataUri => {
      setPictureUrl(dataUri);
    });
  }

  function sendImage() {
    const type = item.idExigeFotoEntrega === "S" ? "M" : null;
    dispatch(
      sendFile({ file: picture.file, sequencial: item.sequencial, tipo: type })
    );
  }
  if (item.entregue !== "S") {
    return null;
  }

  if (item.entregue === "S" && onQueue) {
    return (
      <CardContent>
        <Img src={onQueueItem.picture} />
      </CardContent>
    );
  }
  if (item.file) {
    const folder = "S" ? "minutas" : "entrega";
    const imageUrl = `imagens/${item.cdCliente}/${item.sequencial}/${folder}/${item.file}`;
    return (
      <CardContent>
        <Image image={imageUrl} />
      </CardContent>
    );
  }
  return (
    <>
      <CardContent>Anexar imagem:</CardContent>
      <CardContent>
        <input type="file" onChange={onFileUpload} />
      </CardContent>

      {picture && (
        <>
          <PreviewImg src={pictureUrl} alt="Preview imagem anexada" />
          <CardActions>
            <PrimaryButton
              type="button"
              onClick={() => {
                sendImage();
              }}
            >
              {loadingSendFile ? "Enviando" : "Enviar imagem"}
            </PrimaryButton>
          </CardActions>
        </>
      )}
    </>
  );
};
