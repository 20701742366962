import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaRegUser } from "react-icons/fa";
import { FaLock } from "react-icons/fa";
import { IoIosLock } from "react-icons/io";
import { Container, Form, TextError, LoginButtonIcon } from "./styles";

import { signInRequest } from "~/store/modules/auth/actions";
import Logo from "~/components/Logo";
import Footer from "~/components/Footer";
import { CustomDarkButton } from "~/components/CustomButton";
import CustomInput from "~/components/CustomInput";

export default function Login() {
  const dispatch = useDispatch();
  const { loading, errorMessage } = useSelector(state => state.auth);

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(signInRequest(login, password));
  }

  return (
    <Container
      style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Logo />
      <Form onSubmit={handleSubmit}>
        <div style={{ width: "full" }}>
          <CustomInput
            onChange={e => setLogin(e.target.value)}
            label={"Usuário"}
            name={"name"}
            placeholder={"Digite o seu login"}
            Icon={FaRegUser}
          />
          <CustomInput
            label={"Senha"}
            name={"password"}
            placeholder={"Digite sua senha"}
            Icon={IoIosLock}
            isToggleVisibility={true}
            type={"password"}
            onChange={e => setPassword(e.target.value)}
          />
        </div>

        <div
          style={{
            marginTop: "20px",
          }}
        >
          <CustomDarkButton
            width={"298px"}
            isDisabled={loading}
            type="submit"
            text={loading ? "ENTRANDO..." : "Acessar"}
            isSelected={true}
            rightLogo={<LoginButtonIcon size={20} />}
          />
        </div>
        <TextError>{errorMessage}&nbsp;</TextError>
      </Form>
      <Footer />
    </Container>
  );
}
