import styled from "styled-components";
import Modal from "react-bootstrap/Modal";

export const Container = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  background-color: #fff;
  margin: 10px 5px;
  padding: 10px;
`;

export const CardTitle = styled.h1`
  font-size: 1.3em;
`;

export const CardContent = styled.p`
  color: #555;
`;

export const CardActions = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`;

export const SaveObservationBtn = styled.button.attrs({
  type: "submit",
})`
  flex: 1;
  border: 1px solid #eee;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  color: #fff;
  background-color: #143c96;
`;

export const CustomModal = styled(Modal)`
  z-index: 1070;
`;
