import styled from "styled-components";

export const ImageContainer = styled.div`
  position: relative; /* Enables positioning of the X button */
  display: inline-block;
`;

export const PreviewImg = styled.img`
  border: 0;
  border-radius: 5px;
  padding-top: 4px;
  width: 150px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: #d85656;
  color: white;
  border: none;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;
