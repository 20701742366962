import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import api from "~/services/api";
import { showImage } from "~/store/modules/imageviewer/actions";
import { Img } from "./style";

const maxAge = 60 * 60 * 24 * 1000;

function Image({ image }) {
  const [src, setSrc] = useState("");
  const dispatch = useDispatch();
  const { token } = useSelector(state => state.auth);

  useEffect(() => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
    api
      .get(image, {
        cache: {
          maxAge,
        },
      })
      .then(response => {
        setSrc(response.data);
      })
      .catch(() => {
        setSrc("");
      });
  }, [image, token]);

  const openImageViewer = () => {
    dispatch(showImage(src));
  };

  return (
    <>
      <Img src={src} alt="" onClick={openImageViewer} />
    </>
  );
}

Image.propTypes = {
  image: PropTypes.string.isRequired,
};

export default Image;
