import { persistStore } from "redux-persist";
import createSagaMiddleware from "redux-saga";

import createStore from "./createStore";
import persistReducers from "./persistReducers";
import rootReducer from "./modules/rootReducer";
import rootSaga from "./modules/rootSaga";
import api from "../services/api";
import { signOut } from "./modules/auth/actions";

const sagaMonitor =
  process.env.NODE_ENV === "development"
    ? console.tron.createSagaMonitor()
    : null;

const sagaMiddleware = createSagaMiddleware({ sagaMonitor });

const middlewares = [sagaMiddleware];

const store = createStore(persistReducers(rootReducer), middlewares);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { status } = error.response;
    if (status === 401) {
      store.dispatch(signOut());
    }
    return Promise.reject(error);
  }
);

export { store, persistor };
