import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Fade } from "react-bootstrap";
import { showImage } from "~/store/modules/imageviewer/actions";
import { Img, Wrapper, ImgContainer } from "./style";

export default function Image() {
  const dispatch = useDispatch();
  const { image } = useSelector(state => state.imageviewer);

  const [img, setImg] = useState({ width: 0 });

  useEffect(() => {
    if (image) {
      const theImage = document.createElement("img");
      theImage.src = image;
      setImg(theImage);
    }
  }, [image]);

  function handleOnClick() {
    dispatch(showImage(null));
  }

  const imgWidth =
    img.width < window.innerWidth ? img.width : window.innerWidth;

  const show = image && image.length > 0;

  return (
    <>
      <Fade
        in={show}
        mountOnEnter
        unmountOnExit
        onExited={() => {
          setImg({ width: 0 });
        }}
      >
        <Wrapper onClick={handleOnClick}>
          <ImgContainer>
            <Img src={image} width={imgWidth} />
          </ImgContainer>
        </Wrapper>
      </Fade>
    </>
  );
}
