import styled from "styled-components";
import { IoIosSearch } from "react-icons/io";

export const HeaderContainer = styled.div`
  background-color: #30343e;
  color: #fefefe;
  padding: 16px;
`;

export const TitleLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const TitleVersionContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;
export const PageTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
`;
export const VersionSpan = styled.span`
  font-weight: 300;
  font-size: 12px;
`;
export const DataLine = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 0.9em;
`;
export const Volumes = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Delivery = styled.span`
  font-weight: 300;
  font-size: 14px;
`;

export const VolumesQuantity = styled.span`
  font-weight: 300;
  font-size: 14px;
`;
export const Vehicle = styled.div`
  font-weight: 300;
  font-size: 14px;
`;

export const OnlineBubbleContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.isConnected ? "#4CA522" : "#D85656")};
  padding: 4px 8px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 10px;
`;
export const SearchContainer = styled.div`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-items: center;
  height: 60px;
`;
export const SearchIcon = styled(IoIosSearch)`
  margin-right: 5px;
  color: #343439;
`;
export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 5px;
  padding-left: 10px;
  width: 100%;
  height: 40px;
  background-color: white;
`;

export const Input = styled.input`
  border: none;
  outline: none;
  flex: 1;
  padding: 0 5px;
  width: 100%;
  height: 30px;
  border-radius: 15px;
  ::placeholder {
    color: #464646;
    font-weight: 300;
    font-size: 14px;
  }
`;
