import styled from "styled-components";
import Modal from "react-bootstrap/Modal";

export const CustomModalTitle = styled(Modal.Title)`
  font-size: 1.3em;
`;

export const VolumeList = styled.ul`
  list-style: none;
`;
export const VolumeListItem = styled.li`
  margin-left: 10px;
`;

export const DisabledGpsMessage = styled.p`
  font-weight: bold;
`;
