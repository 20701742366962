import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaTruck } from "react-icons/fa";

import { Container, Form, SubmitButton, InputGroup, PageTitle } from "./styles";

import Footer from "~/components/Footer";
import { signInSuccess, redirectToMain } from "~/store/modules/auth/actions";
import history from "~/services/history";

export default function Transporter() {
  const dispatch = useDispatch();
  const { token, refreshToken } = useSelector(state => state.auth);
  const [vehicle, setVehicle] = useState("");

  const user = {
    userId: useSelector(state => state.user.user.userId),
    isTransporter: useSelector(state => state.user.user.isTransporter),
    vehicle,
  };

  function handleSubmit(e) {
    e.preventDefault();
    dispatch(signInSuccess(token, refreshToken, user));
    dispatch(redirectToMain());
    history.push("/geolocation");
  }

  return (
    <Container>
      <PageTitle>Infome a placa do veículo que realizará a entrega</PageTitle>

      <Form onSubmit={handleSubmit}>
        <InputGroup>
          <FaTruck />
          <input
            type="text"
            placeholder="Informe a placa"
            value={vehicle}
            onChange={e => setVehicle(e.target.value)}
          />
        </InputGroup>
        <SubmitButton>Continuar</SubmitButton>
      </Form>
      <Footer />
    </Container>
  );
}
