import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { checkToken } from "~/store/modules/auth/actions";

import { LoaderWrapper, LoaderSpinner } from "./style";

export default function AppLoader() {
  const loadingAuth = useSelector(states => states.auth.loading);
  const loadingDestination = useSelector(states => states.destinations.loading);
  const dispatch = useDispatch();

  const isLoading = loadingAuth || loadingDestination;

  useEffect(() => {
    dispatch(checkToken());
  }, [dispatch]);

  return (
    isLoading && (
      <LoaderWrapper>
        <LoaderSpinner animation="border" role="status">
          <span className="sr-only">Carregando...</span>
        </LoaderSpinner>
      </LoaderWrapper>
    )
  );
}
