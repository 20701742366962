import styled from "styled-components";

export const DeliveryContainer = styled.div`
  background: #fefefe;
  width: 100%;
  min-height: 100vh;
`;

export const DeliveryTitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const DeliveryTitle = styled.span`
  font-weight: bold;
  color: ${({ cardColor }) => cardColor || "#143c96"};
  font-size: 16px;
`;

export const DeliveryDescription = styled.span`
  color: #0e0e0e;
  font-size: 14px;
`;

export const GpsActiveContainer = styled.div`
  background: #60a84e1f;
  padding: 4px;
  display: flex;
  gap: 4px;
  width: 100px;
  height: 30px;
  border-radius: 4px;
  color: #60a84e;
  justify-content: center;
  align-items: center;
`;

export const InactiveGpsContainer = styled.div`
  padding: 8px;
  display: flex;
  gap: 4px;
  background: #143c960d;
  border-radius: 4px;
  align-items: center;
  height: 48px;
`;

export const AlertContainer = styled.div`
  padding: 7px;
  border-radius: 4px;
  background: #143c96;
  background: rgba(20, 60, 150, 0.1);
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const GpsInactiveText = styled.span`
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #00000080;
`;

export const VolumesInputsContainer = styled.div`
  display: flex;
  padding: 10px;
  border-radius: 4px;
  width: 170px;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #b2b0ae;
`;
