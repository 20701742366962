import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import platform from "platform";

export const sendToSentry = (event, connected) => {
  const info = platform.parse(window.navigator.userAgent);
  event = {
    ...event,
    osInfo: {
      name: info.name, // Browser name
      version: info.version, // Browser version
      os: info.os.family, // Operating system
      description: info.description, // Full description
      layout: info.layout, // Rendering engine
      product: info.product, // Product name
      manufacturer: info.manufacturer, // Manufacturer
      device: info.device, // Device name
      ua: info.ua,
    },
  };
  if (connected) {
    Sentry.captureException(event);
  } else {
    const storedEvents = JSON.parse(localStorage.getItem("sentryEvents")) || [];
    storedEvents.push(event);
    localStorage.setItem("sentryEvents", JSON.stringify(storedEvents));
  }
};

export const sendStoredEvents = () => {
  const storedEvents = JSON.parse(localStorage.getItem("sentryEvents")) || [];
  storedEvents.forEach(event => Sentry.captureException(event));
  localStorage.removeItem("sentryEvents");
};
