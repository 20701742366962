export function getBase64FromBlob(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = function(event) {
      const dataUrl = event.target.result;
      resolve(dataUrl);
    };

    reader.onerror = function(error) {
      reject(error);
    };

    reader.readAsDataURL(blob);
  });
}
export function dataURLToBlob(dataUrl) {
  const base64Data = dataUrl.split(",")[1];
  const binaryData = atob(base64Data);
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }
  const blob = new Blob([uint8Array], { type: "image/png" }); // Adjust the type if needed

  return blob;
}
