import { takeLatest, call, put, all, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import api from "~/services/api";
import {
  setDestinationsToDeliver,
  setDestinationsDelivered,
  setDestinationsInfo,
  setDestinationsError,
  setLoadingSendFile,
  setSendFileReturn,
  Actions,
} from "./actions";
import { redirectToMain } from "../auth/actions";
import { sendToSentry } from "~/sentry";

export function* Destinations() {
  try {
    const { token } = yield select(state => state.auth);
    const { user } = yield select(state => state.user);

    api.defaults.headers.Authorization = `Bearer ${token}`;

    const response = yield call(api.get, "/entregas", {
      params: {
        cdEntregador: user.userId,
        isTransportador: user.isTransporter.toString(),
        dsPlacaVeiculoEntrega: user.vehicle,
      },
    });

    yield put(setDestinationsToDeliver(response.data.naoEntregue));
    yield put(setDestinationsDelivered(response.data.entregue));
    yield put(setDestinationsInfo(response.data.info));
  } catch (err) {
    const sentryMessage = {
      message: "Não foi possivel carregar a lista de entregas *Destinations",
      error: err.message,
      jsonError: JSON.stringify(err),
    };
    sendToSentry(sentryMessage, true);
    yield put(setDestinationsError());
    toast.error("Não foi possivel carregar a lista de entregas");
  }
}

export function* setObservation({ payload }) {
  const { delivery } = payload;
  const { Connected } = yield select(state => state.connection);
  const { token } = yield select(state => state.auth);

  try {
    api.defaults.headers.Authorization = `Bearer ${token}`;

    if (Connected) {
      const deliveryData = {
        sqEntrega: delivery.sequencial,
        dsObservacao: delivery.observacao,
      };
      const retorno = yield call(api.put, "/observacoes", deliveryData);
      if (retorno.data.returnCode !== 0) {
        toast.error("Não foi possível adicionar a observação");
        return;
      }
    }
  } catch (error) {
    const sentryMessage = {
      message: "Erro ao possível adicionar a observação",
      error: error.message,
      jsonError: JSON.stringify(error),
    };
    sendToSentry(sentryMessage, true);
    toast.error("Erro ao possível adicionar a observação");
    toast.error("Erro ao adicionar a observação");
  }
}

export function* SendFile({ payload }) {
  const { file, sequencial, tipo } = payload;
  const { Connected } = yield select(state => state.connection);
  const { token } = yield select(state => state.auth);

  try {
    api.defaults.headers.Authorization = `Bearer ${token}`;

    if (Connected) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("sqEntrega", sequencial);
      if (tipo) {
        formData.append("idTipoEnvio", tipo);
      }
      const retornoUpload = yield call(api.post, "/files", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (!retornoUpload) {
        toast.error(
          "Não foi possível enviar a imagem para o servidor! Tente novamente mais tarde, a imagem está salva na sua pasta de downloads"
        );
      } else if (!payload.noReload) {
        yield put(redirectToMain());
      }

      yield put(setSendFileReturn(retornoUpload.data));
    }
    yield put(setLoadingSendFile(false));
  } catch (error) {
    const sentryMessage = {
      message: "Erro ao enviar imagem",
      error: error.message,
      jsonError: JSON.stringify(error),
    };
    sendToSentry(sentryMessage, true);
    toast.error("Erro ao enviar imagem");
    yield put(setLoadingSendFile(false));
  }
}

export default all([
  takeLatest(Actions.SET_NEW_OBSERVATION, setObservation),
  takeLatest(Actions.SEND_FILE, SendFile),
]);
