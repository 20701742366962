import styled from "styled-components";
import Modal from "react-bootstrap/Modal";

export const UnavailableModal = styled(Modal)`
  z-index: 1070;
`;

export const DeliverWithoutGpsBtn = styled.button`
  border: 0;
  color: #143c96;
  background-color: rgba(0, 0, 0, 0);
  padding: 5px 0;
  margin-right: 15px;
`;
